import React from "react";
import fespaText from "../assets/fespa-text.png";
import flessibilita from "../assets/flessibilita.jpg";
import equilibrio from "../assets/equilibrio.jpg";
import sostenibilita from "../assets/sostenibilita.jpg";
import personalizzazione from "../assets/personalizzazione.jpg";
import approcio from "../assets/approcio.jpg";

export default function PictureCards() {
  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-center align-items-center mb-5 position-relative">
        <img
          src={fespaText}
          className="pictureCards-imageBg"
          alt="pictureCard"
        />
        <div className="pictureCards-wrapper">
          <p className="card-heading text-center">
            Metodo rivoluzionario che permette a qualunque donna di poter
            raggiungere tutti i propri obiettivi fisici senza stress perché si
            basa su 5 tasselli fondamentali
          </p>
        </div>
      </div>
      <div className="row pt-5 align-items-center">
        <div className="col-lg-7 col-md-7 col-sm-12">
          <img
            src={flessibilita}
            alt="flessibilita"
            className="pictureCards-image"
          />
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12">
          <div className="pictureCards-contentCard pictureCards-contentCard-left">
            <p className="pictureCards-nr">numero 1</p>
            <p className="pictureCards-title">FLESSIBILITA'</p>
            <p className="card-content">
              La capacità di poter scegliere liberamente i cibi che più ti
              piacciono, senza privazioni e rinunce.
            </p>
          </div>
        </div>
      </div>
      <div className="row pt-5 align-items-center flex-md-row flex-column-reverse">
        <div className="col-lg-5 col-md-5 col-sm-12">
          <div className="pictureCards-contentCard pictureCards-contentCard-right">
            <p className="pictureCards-nr">numero 2</p>
            <p className="pictureCards-title">EQUILIBRIO</p>
            <p className="card-content">
              L'equilibrio alimentare è la capacità di saperti ascoltare e
              nutrirti in base alle tue esigenze e voglie.
            </p>
          </div>
        </div>
        <div className="col-lg-7 col-md-7 col-sm-12">
          <img
            src={equilibrio}
            alt="equilibrio"
            className="pictureCards-image"
          />
        </div>
      </div>
      <div className="row pt-5 align-items-center">
        <div className="col-lg-7 col-md-7 col-sm-12">
          <img
            src={sostenibilita}
            alt="sostenibilita"
            className="pictureCards-image"
          />
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12">
          <div className="pictureCards-contentCard pictureCards-contentCard-left">
            <p className="pictureCards-nr">numero 3</p>
            <p className="pictureCards-title">SOSTENIBILITA'</p>
            <p className="card-content">
              Grazie ad una strategia alimentare e un allenamento sostenibile,
              riuscirai ad essere in forma 365 giorni su 365.
            </p>
          </div>
        </div>
      </div>
      <div className="row pt-5 align-items-center flex-md-row flex-column-reverse">
        <div className="col-lg-5 col-md-5 col-sm-12">
          <div className="pictureCards-contentCard pictureCards-contentCard-right">
            <p className="pictureCards-nr">numero 4</p>
            <p className="pictureCards-title">PERSONALIZZAZIONE</p>
            <p className="card-content">
              Ciò che manca in tutte le diete copia e incolla che finora hai
              seguito: alimentazione e allenamento non solo devono
              necessariamente essere personalizzati ma, devono anche (e
              soprattutto) adattarsi al tuo stile di vita.
            </p>
          </div>
        </div>
        <div className="col-lg-7 col-md-7 col-sm-12">
          <img
            src={personalizzazione}
            alt="personalizzazione"
            className="pictureCards-image"
          />
        </div>
      </div>
      <div className="row pt-5 align-items-center">
        <div className="col-lg-7 col-md-7 col-sm-12">
          <img src={approcio} alt="approcio" className="pictureCards-image" />
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12">
          <div className="pictureCards-contentCard pictureCards-contentCard-left">
            <p className="pictureCards-nr">numero 5</p>
            <p className="pictureCards-title">APPROCCIO MENTALE</p>
            <p className="card-content">
              Tutto parte dalla mente: cambiare il tuo rapporto con il cibo e
              corpo è fondamentale per poter raggiungere un benessere
              psico-fisico che ti consentirà di mantenere i risultati nel tempo.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
