import React from "react";
import appImg from "../assets/app.png";
import descGirl from "../assets/steps.png";
import Card from "../components/Card";

export default function Description() {
  return (
    <div className=" description">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <img src={appImg} style={{ width: "100%" }} alt="phone app" />
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="">
              <p className="title">basato sul metodo fespa</p>
              <p className="heading">
                Il primo percorso di ri-educazione alimentare ed Online Coaching
                in Italia
              </p>
              <div className="description-boxes d-md-flex justify-content-between align-items-center ">
                <div className="description-box-gradient me-lg-2">
                  <div className="description-box">
                    <p className="description-box-heading">1030+</p>
                    <p className="description-box-subHeading">
                      Guerriere Vincenti
                    </p>
                  </div>
                </div>
                <div className="description-box-gradient me-lg-2">
                  <div className="description-box">
                    <p className="description-box-heading">24000+</p>
                    <p className="description-box-subHeading">
                      Guerriere in forma
                    </p>
                  </div>
                </div>
                <div className="description-box-gradient">
                  <div className="description-box">
                    <p className="description-box-heading">24ORE</p>
                    <p className="description-box-subHeading">
                      7/7 GIORNI SUPPORTO
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative">
        <img
          src={descGirl}
          alt="description girl"
          className="description-image d-none d-md-block"
        />
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <div className="row align-items-center flex-lg-row flex-column-reverse">
                <div className="col-lg-6 col-md-12">
                  <div className="mb-3">
                    <Card
                      bg={"#FFF4FF"}
                      nr="03"
                      title="Riscopri il piacere di mangiare insieme ai tuoi cari "
                      content="Rispetto alle classiche diete o i regimi da te seguiti finora, potrai finalmente cucinare la stessa pietanza per ogni componente della tua famiglia, evitando lo stress di sporcare pentole su pentole. "
                    />
                  </div>
                  <div className="mb-3">
                    <Card
                      bg={"#FFF4F4"}
                      nr="04"
                      title="Preparati ad essere in forma mangiando pasta ogni giorno "
                      content="Già. Ti sembrerà un'utopia ma... è tutto vero. Avrai modo di gustare due piatti di pasta ogni giorno, senza mai più rinunciare ai tuoi amici carboidrati."
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="mb-3">
                    <p className="card-heading">
                      Ma cos’è protocollo guerriera vincente?
                    </p>
                    <p className="card-content">
                      Basato, sul Metodo FESPA, è il primo percorso di
                      ri-educazione alimentare ed Online Coaching in Italia.
                      Definito anche come la “Ferrari del dimagrimento” o come
                      la "Vacanza verso il benessere" È un programma cucito
                      sulla singola donna, estremamente sartoriale.
                    </p>
                    <p className="card-content">
                      Già, perché quello di cui stiamo parlando è IL PERCORSO
                      PIÙ SEMPLICE E VELOCE PER TORNARE IN FORMA che si basa
                      completamente sul Metodo F.E.S.P.A.
                    </p>
                  </div>
                  <div className="mb-3">
                    <Card
                      bg={"#F6F4FF"}
                      nr="01"
                      title="Dimentica pillole, gocce, sostitutivi del pasto o altre soluzioni pseudo magiche."
                      content="Con il Metodo FESPA, potrai finalmente essere in forma senza dover utilizzare pillole, sostitutivi del pasto, gocce e prodotti simili. "
                    />
                  </div>
                  <div className="mb-3">
                    <Card
                      bg={"#F4F7FF"}
                      nr="02"
                      title="Dimentica sessioni di allenamento lunghe e noiose"
                      content="Grazie ad una pianificazione dell'allenamento specifica per le tue esigenze, potrai allenarti anche comodamente da casa, con sessioni brevi ed efficaci. La tua vita non può girare intorno a ''palestra e alimentazione''. "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
