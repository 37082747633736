import React from "react";
import girlsBg from "../assets/girls.png";
import dot from "../assets/dots.png";

export default function Types() {
  return (
    <div className="types">
      <img src={girlsBg} alt="girlsBg" className="types-imageBg" />
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="types-wrapper">
              <p className="card-heading">
                A QUALE TIPO DI DONNE CI{" "}
                <span className="color-primary">RIVOLGIAMO</span>
              </p>
              <div className="d-flex align-items-center mb-3">
                <img src={dot} alt="dot" className=" me-2 " />
                <p className="content-16 p-no-margin">
                  Donne che, nonostante il poco tempo a disposizione vogliono
                  essere in forma senza dieta o stress;
                </p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <img src={dot} alt="dot" className=" me-2 " />
                <p className="content-16 p-no-margin">
                  Donne che desiderano realmente abbandonare diete fallimentari,
                  pillole, bibitoni o altre soluzioni poco affidabili;
                </p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <img src={dot} alt="dot" className=" me-2 " />
                <p className="content-16 p-no-margin">
                  ​Donne che, nonostante la menopausa e problemi di tiroide,
                  vogliono rimodellare il proprio corpo.​
                </p>
              </div>
            </div>
            <div className="types-wrapper">
              <p className="card-heading">
                A CHI
                <span className="color-primary"> NON CI RIVOLGIAMO</span>
              </p>
              <div className="d-flex align-items-center mb-3">
                <img src={dot} alt="dot" className=" me-2 " />
                <p className="content-16 p-no-margin">
                  Donne che non hanno realmente voglia di cambiare e sono alla
                  ricerca del sostitutivo del pasto miracoloso;
                </p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <img src={dot} alt="dot" className=" me-2 " />
                <p className="content-16 p-no-margin">
                  Persone che, nonostante i fallimenti, vogliono continuare a
                  seguire schemi rigidi e imposti;
                </p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <img src={dot} alt="dot" className=" me-2 " />
                <p className="content-16 p-no-margin">
                  Donne poco motivate e non disposte ad investire in un percorso
                  per non riprendere mai più i chili persi.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 align-self-center">
            <div className="types-people">
              <p className="types-people-nr">1030+</p>
              <p className="card-content">Oltre mille Donne hanno deciso di indossare l'armatura da vera Guerriera Vincente</p>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 align-self-end mt-3">
          <div className="types-people">
              <p className="types-people-nr">24000+</p>
              <p className="card-content">Sei pronta a raggiungere anche tu un fisico magro, tonico ed armonico senza rinunce?</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
