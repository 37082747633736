import React from "react";
import Nav from "../components/Nav";
import bg from "../assets/fespa-bg.png";
import googlePlay from "../assets/google-play.png";
import appStore from "../assets/app-store.png";

export default function Hero() {
  return (
    <div className="hero">
      <img className="hero-bg" src={bg} alt='background' />
      <div className="container">
        <Nav />
        <div className="hero-action">
          <p className="title">Healthy & Happiness</p>
          <p className="hero-action-text mb-5">
            Il percorso più semplice e veloce per{" "}
            <span className="hero-action-text hero-action-span">
              tornare in forma
            </span>
          </p>
          <div className="d-flex">
            <img src={googlePlay} className="me-2" alt='google play icon'/>
            <img src={appStore} alt='app store icon' />
          </div>
        </div>
      </div>
    </div>
  );
}
