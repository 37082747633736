import React from "react";

export default function Card(props) {
  return (
    <div className="card" style={{ backgroundColor: props.bg }}>
      {props.nr && (
        <div className="card-nr">
          <p className="card-nr-text">{props.nr}</p>
        </div>
      )}
      <p className="title-card">{props.title}</p>
      <p className="card-content">{props.content}</p>
    </div>
  );
}
