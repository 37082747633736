import React from "react";
import { HiOutlineUserCircle } from "react-icons/hi";

export default function Nav() {
  return (
    <div className="nav d-flex justify-content-between align-items-center">
      <p className="nav-logo">fespa</p>
      <a href="https://client.metodofespa-app.com/" target='_blank'>
        <div className="nav-button d-none d-lg-block">
          <p className="nav-button-text">Accedi</p>
        </div>
        <div className="d-lg-none">
          <HiOutlineUserCircle className="nav-icon" />
        </div>
      </a>
    </div>
  );
}
