import React from "react";
import PrivacyPolicyModal from "./PrivacyPolicyModal";

export default function () {
  return (
    <div className="footer">
      <div className="container">
        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
          <div className="footer-item">
            <p className="footer-item-text" style={{marginTop: 4}}>FESPA 2022 ©</p>
          </div>
          {/* <div className="footer-item">
            <p className="footer-item-text">Cookie Policy</p>
          </div> */}
          <div className="footer-item">
            {/* <p className='footer-item-text'>Privacy Policy</p> */}
            <PrivacyPolicyModal
              openModal={<p className="footer-item-text">Privacy Policy</p>}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
