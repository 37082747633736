import React from "react";
import ballBg from "../assets/ball.png";
import dot from "../assets/dots.png";
import Card from "../components/Card";

export default function Bonus() {
  return (
    <div className="position-relative mt-5">
      <img src={ballBg} alt="ballBg" className="bonus-imageBg" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="bonus-wrapper">
              <p className="card-heading">
                Se riesco ad avere l'opportunità di accedere, cosa otterrò in
                pratica?
              </p>
              <p className="content-16">E ancora</p>
              <div className="d-flex align-items-center mb-3">
                <img src={dot} alt="dot" className=" me-2 " />
                <p className="content-16 p-no-margin">
                  Come eliminare la fame nervosa e le abbuffate{" "}
                  <span className="color-primary">(BONUS)</span>​
                </p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <img src={dot} alt="dot" className=" me-2 " />
                <p className="content-16 p-no-margin">
                  Come avere una pelle morbida ed elastica grazie ad una
                  corretta idratazione{" "}
                  <span className="color-primary">(BONUS)</span>​
                </p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <img src={dot} alt="dot" className=" me-2 " />
                <p className="content-16 p-no-margin">
                  I 10 segreti per essere una vera Guerriera Vincente in Forma{" "}
                  <span className="color-primary">(BONUS)</span>​
                </p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <img src={dot} alt="dot" className=" me-2 " />
                <p className="content-16 p-no-margin">
                  ​Approccio mentale: per raggiungere tutti gli obiettivi e
                  mantenere i risultati nel tempo
                  <span className="color-primary">(BONUS)</span>​
                </p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <img src={dot} alt="dot" className=" me-2 " />
                <p className="content-16 p-no-margin">
                  ​Come non avere più quel senso di fame improvviso tra un pasto
                  e l'altro
                  <span className="color-primary">(BONUS)</span>​
                </p>
              </div>
              <p className="content-16">
                ...e tanto altro ancora, sempre in aggiornamento
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12 bonus-padding-top">
                <div className="mt-4">
                  <Card
                    bg="#fff"
                    title="Pianificazione strategica e sartoriale"
                    content="Avrai accesso ad un percorso sartoriale, cucito addosso alle Tue esigenze specifiche. Solo mediante la personalizzazione è possibile raggiungere un fisico magro, tonico ed armonico. "
                  />
                </div>
                <div className="mt-4">
                  <Card
                    bg="#fff"
                    title="Mai più cucine separate"
                    content="Mai più cucine separate e decine di pentole da lavare! A differenza delle solite dietine, sedersi a tavola e gustare le stesse pietanze che mangia la propria famiglia non sarà più un problema."
                  />
                </div>
                <div className="mt-4">
                  <Card
                    bg="#fff"
                    title="BONUS
                    ​Area riservata personale"
                    content="Avrai accesso ad un'area riservata sempre in aggiornamento, con informazioni volte al velocizzare il processo di dimagrimento, aumento di peso o tonificazione: ottenendo una educazione e libertà alimentare a 360°"
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mt-4">
                  <Card
                    bg="#fff"
                    title="Accesso a tutti i segreti del Metodo F.E.S.P.A."
                    content="Come essere in forma senza rinunce, rendendoti conto di quanto finora sei stata presa in giro da diete chetogeniche o da chi ti ha sempre proposto pillole / sostitutivi del pasto per perdere grasso "
                  />
                </div>
                <div className="mt-4">
                  <Card
                    bg="#fff"
                    title="Supporto 24 ore su 24"
                    content="Per garantire il massimo risultato avrai la possibilità di avere un supporto 24 ore su 24 direttamente con Federica e con i nostri Consulenti, definiti anche ''Angeli Custodi'' dalle nostre Clienti. "
                  />
                </div>
                <div className="mt-4">
                  <Card
                    bg="#fff"
                    title="Possibilità di allenarsi da casa"
                    content="Per tonificare e rimodellare completamente il corpo, non c'è bisogno di andare necessariamente in palestra. Quando alla base c'è una personalizzazione dell'allenamento, si possono ottenere ottimi risultati anche comodamente da casa."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
