import React, { useEffect, useState } from "react";
import { HiX } from "react-icons/hi";
import axios from "axios";

export default function PrivacyPolicyModal(props) {
  const [legacy, setLegacy] = useState("");

  useEffect(() => {
    axios
      .get("https://api3.metodofespa-app.com/api/legacy/all")
      .then((response) => setLegacy(response.data.list))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="cookie">
      <a type="button" data-bs-toggle="modal" data-bs-target="#privacyModal">
        {props.openModal}
      </a>

      <div
        className={`modal fade overlay-dark`}
        id="privacyModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable cookie-modal-dialog">
          <div className="modal-content">
            <div className="modal-header cookie-modal-header justify-content-center">
              <button
                type="button"
                className="cookie-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <HiX size={30} />
              </button>
              <p className="cookie-title">Privacy Policy</p>
            </div>
            <div className="modal-body" style={{color: '#000'}}>
              {legacy.length > 0 &&
                legacy.map((item, index) => (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html:
                        item.category === "Privacy Policy"
                          ? item.content
                          : null,
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
