import React from "react";
import fitness from "../assets/fitness.jpg";

export default function Faq() {
  return (
    <div className="container faq">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <img src={fitness} alt="fitness" className="faq-image" />
          <div className="faq-image-wrapper  mb-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="faq-fitness">
                <p className="faq-fitness-text">FITNESS</p>
              </div>
              <p className="faq-by">By FESPA</p>
            </div>
            <p className="faq-text">
              Grazie ad una strategia alimentare e un allenamento sostenibile,
              riuscirai ad essere in forma 365 giorni su 365.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <p className="card-heading text-center">DOMANDE FREQUENTI</p>
          <div>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <p className="content-16 p-no-margin">
                      Sono in menopausa. Posso comunque aderire al percorso?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="card-content">
                      Se rispetti i requisiti da noi richiesti... Assolutamente
                      sì. La menopausa non va ad intralciare il raggiungimento
                      di un fisico magro, tonico ed armonico. La maggior parte
                      delle donne che seguiamo sono in menopausa e ottengono
                      tutte ottimi risultati.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <p className="content-16 p-no-margin">
                      Soffro di problemi di tiroide, potete aiutarmi?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="card-content">
                      Certo. Seguiamo molte donne con tiroide di Hashimoto che,
                      grazie ai nostri percorsi, hanno perso definitivamente i
                      chili di troppo.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <p className="content-16 p-no-margin">
                      Chi mi garantisce che otterrò risultati?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="card-content">
                      Puoi guardare le nostre Testimonianze e parlare con chi ha
                      già rivoluzionato il proprio corpo all'interno del nostro
                      gruppo facebook privato Guerriere in Forma. Seguendo il
                      percorso, i risultati sono garantiti.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <p className="content-16 p-no-margin">
                      Dovrò assumere farmaci o pillole?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="card-content">
                      Assolutamente no. Noi di Guerriere in Forma siamo contro
                      l'assunzione di pillole, sostitutivi del pasto, gocce e
                      altre soluzioni poco raccomandabili. Non proponiamo alcun
                      tipo di farmaco.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    <p className="content-16 p-no-margin">
                      Che tipo di supporto verrà erogato?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="card-content">
                      Oltre al supporto di Federica, avrai un supporto H24 con
                      il tuo Consulente di Riferimento.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    <p className="content-16 p-no-margin">
                      Devo andare necessariamente in palestra?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="card-content">
                      No. Puoi ottenere risultati anche allenandoti comodamente
                      da casa con i nostri percorsi.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSeven">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    <p className="content-16 p-no-margin">
                      Quanto durano i vostri allenamenti?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="card-content">
                      L'allenamento é sartoriale, proprio come il percorso
                      alimentare. Siamo contro gli allenamenti lunghi ed
                      estenuanti, in media possono durare dai 10 ai 40 minuti.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingEight">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    <p className="content-16 p-no-margin">
                      Cosa devo fare per iniziare questo programma?
                    </p>
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEight"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="card-content">
                      Compila il questionario per essere contattata entro 24/48
                      ore. Un Consulente analizzerà la tua situazione e, qualora
                      dovessi rispettare i requisiti da noi richiesti, ti verrà
                      proposto solo ciò che si adatta alle tue esigenze
                      specifiche.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
