import React, { useState } from "react";
import googlePlay from "../assets/google-play.png";
import appStore from "../assets/app-store.png";
import Popup from "../components/Popup";
import PrivacyPolicyModal from "../components/PrivacyPolicyModal";

export default function FormSection() {
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [obiettivo, setObiettivo] = useState("");
  const [money, setMoney] = useState("");
  const [checked, setChecked] = useState(false);
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  const checkAccepted = () => !!checked;

  const checkFields = () => {
    if (!!age && !!name && !!email && !!phone && !!obiettivo && !!money) {
      return true;
    } else {
      return false;
    }
  };

  const onSendRequest = () => {
    if (checkAccepted()) {
      var formdata = new FormData();
      formdata.append(
        "object",
        JSON.stringify({
          name: name,
          age: age,
          mail: email,
          tel: phone,
          contact: obiettivo,
          invest: money,
        })
      );

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(
        "https://api3.metodofespa-app.com/api/login/add_leads",
        requestOptions
      )
        .then((response) => {
          setShowPopup({
            visible: true,
            heading: "SUCCESS",
            message: "Your form has been successfully submitted",
            button: "OK",
            onClose: () => setShowPopup({ visible: false }),
            onClick: () => setShowPopup({ visible: false }),
          });
        })
        .catch((error) => console.log("error", error));
    } else {
      setShowPopup({
        visible: true,
        heading: "ATTENTION",
        message:
          "Per continuare la registrazione, bisogna accettare i Termini e Condizioni",
        button: "Try Again",
        onClose: () => setShowPopup({ visible: false }),
        onClick: () => setShowPopup({ visible: false }),
      });
    }
  };

  return (
    <div className="formSection mt-5">
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      <div className="container pt-5">
        <p className="formSection-heading">
          Prenota la tua Consulenza Gratuita
        </p>
        <p className="formSection-subHeading p-no-margin">
          NESSUN VINCOLO O IMPEGNO.
        </p>
        <p className="formSection-subHeading">
          Riceverai un'analisi dettagliata della tua situazione e scoprirai se i
          nostri percorsi si adattano alle tue esigenze.
        </p>
        <div className="row mt-5">
          <div className="col-md-6 col-sm-12">
            <p className="formSection-heading-small">
              Finalmente un Percorso Semplice, senza restrizioni - adatto anche
              alle donne più impegnate. Riceverai un'analisi dettagliata della
              tua situazione e scoprirai se i nostri percorsi si adattano alle
              tue esigenze.
            </p>
            <div className="formSection-text-wrapper mb-4">
              <p className="formSection-heading-small">
                Accesso a tutti i segreti del Metodo F.E.S.P.A.
              </p>
              <p className="formSection-text-content">
                Come essere in forma senza rinunce, rendendoti conto di quanto
                finora sei stata presa in giro da diete chetogeniche o da chi ti
                ha sempre proposto pillole / sostitutivi del pasto per perdere
                grasso.
              </p>
            </div>
            <div className="formSection-text-wrapper mb-4">
              <p className="formSection-heading-small">
                Pianificazione strategica e sartoriale
              </p>
              <p className="formSection-text-content">
                Avrai accesso ad un percorso sartoriale, cucito addosso alle Tue
                esigenze specifiche. Solo mediante la personalizzazione è
                possibile raggiungere un fisico magro, tonico ed armonico.
              </p>
            </div>
            <div className="formSection-text-wrapper mb-4">
              <p className="formSection-heading-small">Supporto 24 ore su 24</p>
              <p className="formSection-text-content">
                Per garantire il massimo risultato avrai la possibilità di avere
                un supporto 24 ore su 24 direttamente con Federica e con i
                nostri Consulenti, definiti anche ''Angeli Custodi'' dalle
                nostre Clienti.
              </p>
            </div>
            <div className="formSection-text-wrapper mb-4">
              <p className="formSection-heading-small">
                ​Mai più cucine separate
              </p>
              <p className="formSection-text-content">
                ​Mai più cucine separate e decine di pentole da lavare! A
                differenza delle solite dietine, sedersi a tavola e gustare le
                stesse pietanze che mangia la propria famiglia non sarà più un
                problema.
              </p>
            </div>
            <div className="formSection-text-wrapper mb-4">
              <p className="formSection-heading-small">
                Possibilità di allenarsi da casa
              </p>
              <p className="formSection-text-content">
                Per tonificare e rimodellare completamente il corpo, non c'è
                bisogno di andare necessariamente in palestra. Quando alla base
                c'è una personalizzazione dell'allenamento, si possono ottenere
                ottimi risultati anche comodamente da casa.
              </p>
            </div>
            <div className="formSection-text-wrapper mb-4">
              <p className="formSection-heading-small">
                Area riservata personale
              </p>
              <p className="formSection-text-content">
                Avrai accesso ad un'area riservata sempre in aggiornamento, con
                informazioni volte al velocizzare il processo di dimagrimento,
                aumento di peso o tonificazione: ottenendo una educazione e
                libertà alimentare a 360°
              </p>
            </div>
            <div className="formSection-personalArea pt-5 mb-3">
              <p className="formSection-personalArea-heading">Area Personale</p>
              <p className="formSection-personalArea-subHeading">
                disponibile su tutti i dispositivi{" "}
              </p>
              <div className="d-flex">
                <img src={googlePlay} className="me-2" alt="google play icon" />
                <img src={appStore} alt="app store icon" />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 ">
            <p className="formSection-heading-small">
              Iniziamo...come ti chiami? *
            </p>
            <input
              className="formSection-input mb-3"
              placeholder="Nome e Cognome"
              onChange={(e) => setName(e.target.value)}
            />
            <p className="formSection-heading-small">Quanti anni hai? *</p>
            <input
              type="number"
              className="formSection-input mb-3"
              placeholder="Età"
              onChange={(e) => setAge(e.target.value)}
            />
            <p className="formSection-heading-small">
              Qual è il tuo obiettivo? *
            </p>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="mb-3">
                  <input
                    type="radio"
                    id="op1"
                    name="obiettivo"
                    value={"Vorrei dimagrire"}
                    onChange={(e) => setObiettivo(e.target.value)}
                    className="formSection-input-radio"
                  />
                  <label
                    htmlFor="op1"
                    className="formSection-input-radio-label"
                  >
                    Vorrei dimagrire
                  </label>
                </div>
                <div className="mb-3">
                  <input
                    type="radio"
                    id="op2"
                    name="obiettivo"
                    value={"Vorrei avere più energia durante il giorno"}
                    onChange={(e) => setObiettivo(e.target.value)}
                    className="formSection-input-radio"
                  />
                  <label
                    htmlFor="op2"
                    className="formSection-input-radio-label"
                  >
                    Vorrei avere più energia durante il giorno
                  </label>
                </div>
                <div className="mb-3">
                  <input
                    type="radio"
                    id="op3"
                    name="obiettivo"
                    value={"Vorrei ottenere un’educazione alimentare"}
                    onChange={(e) => setObiettivo(e.target.value)}
                    className="formSection-input-radio"
                  />
                  <label
                    htmlFor="op3"
                    className="formSection-input-radio-label"
                  >
                    Vorrei ottenere un’educazione alimentare
                  </label>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="mb-3">
                  <input
                    type="radio"
                    id="op4"
                    name="obiettivo"
                    value={"Vorrei tonificarmi"}
                    onChange={(e) => setObiettivo(e.target.value)}
                    className="formSection-input-radio"
                  />
                  <label
                    htmlFor="op4"
                    className="formSection-input-radio-label"
                  >
                    Vorrei tonificarmi
                  </label>
                </div>
                <div className="mb-3">
                  <input
                    type="radio"
                    id="op5"
                    name="obiettivo"
                    value={"Vorrei essere in salute"}
                    onChange={(e) => setObiettivo(e.target.value)}
                    className="formSection-input-radio"
                  />
                  <label
                    htmlFor="op5"
                    className="formSection-input-radio-label"
                  >
                    Vorrei essere in salute
                  </label>
                </div>
                <div className="mb-3">
                  <input
                    type="radio"
                    id="op6"
                    name="obiettivo"
                    value={"Tutte le opzioni"}
                    onChange={(e) => setObiettivo(e.target.value)}
                    className="formSection-input-radio"
                  />
                  <label
                    htmlFor="op6"
                    className="formSection-input-radio-label"
                  >
                    Tutte le opzioni
                  </label>
                </div>
              </div>
            </div>
            <p className="formSection-heading-small">
              Quanto saresti disposta ad investire se i nostri percorsi ti
              facessero raggiungere in modo garantito tutti i tuoi obiettivi? *
            </p>
            <div className="mb-3">
              <input
                type="radio"
                id="op7"
                name="invest"
                value={"Meno di 500"}
                onChange={(e) => setMoney(e.target.value)}
                className="formSection-input-radio"
              />
              <label htmlFor="op7" className="formSection-input-radio-label">
                Meno di 500
              </label>
            </div>
            <div className="mb-3">
              <input
                type="radio"
                id="op8"
                name="invest"
                value={"Più di 500"}
                onChange={(e) => setMoney(e.target.value)}
                className="formSection-input-radio"
              />
              <label htmlFor="op8" className="formSection-input-radio-label">
                Più di 500
              </label>
            </div>
            <div className="mb-3">
              <input
                type="radio"
                id="op9"
                name="invest"
                value={"Sarei disposta ad investire, ma vorrei valutare"}
                onChange={(e) => setMoney(e.target.value)}
                className="formSection-input-radio"
              />
              <label htmlFor="op9" className="formSection-input-radio-label">
                Sarei disposta ad investire, ma vorrei valutare
              </label>
            </div>
            <div className="mb-3">
              <input
                type="radio"
                id="op10"
                name="invest"
                value={
                  "Non sarei disposta ad investire neanche 1 euro (La consulenza non verrà prenotata)."
                }
                onChange={(e) => setMoney(e.target.value)}
                className="formSection-input-radio"
              />
              <label htmlFor="op10" className="formSection-input-radio-label">
                Non sarei disposta ad investire neanche 1 euro (La consulenza
                non verrà prenotata).
              </label>
            </div>
            <p className="formSection-heading-small">
              Inserisci la tua e-mail*
            </p>
            <input
              type="email"
              className="formSection-input mb-3"
              placeholder="E-mail"
              onChange={(e) => setEmail(e.target.value)}
            />
            <p className="formSection-heading-small">
              Inserisci il tuo numero di telefono*
            </p>
            <input
              type="tel"
              className="formSection-input mb-3"
              placeholder="Telefono"
              onChange={(e) => setPhone(e.target.value)}
            />
            <div className="d-flex justify-content-between align-items-center">
              <PrivacyPolicyModal
                openModal={
                  <p className="formSection-policy-text">
                    Dichiaro di avere 18 anni e di aver letto e Accetto
                    l’informativa sul Trattamento dei dati personali
                  </p>
                }
              />
              <input
                type="checkbox"
                value={checked}
                onChange={() => setChecked(!checked)}
              />
            </div>
            <div
              className="formSection-button"
              onClick={() => {
                checkFields()
                  ? onSendRequest()
                  : setShowPopup({
                      visible: true,
                      heading: "ATTENTION",
                      message: "All the fields are required!",
                      button: "Try Again",
                      onClose: () => setShowPopup({ visible: false }),
                      onClick: () => setShowPopup({ visible: false }),
                    });
              }}
            >
              <p className="formSection-button-text">
                Clicca qui e invia la tua richiesta di consulenza gratuita
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
