import 'bootstrap/dist/js/bootstrap.bundle';
import Hero from "./layout/Hero";
import Description from "./layout/Description";
import PictureCards from "./layout/PictureCards";
import Bonus from "./layout/Bonus";
import Types from "./layout/Types";
import Faq from "./layout/Faq";
import FormSection from './layout/FormSection';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
     <Hero />
     <Description />
     <PictureCards />
     <Bonus />
     <Types />
     <Faq />
     <FormSection />
     <Footer />
    </div>
  );
}

export default App;
